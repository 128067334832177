<template>
  <div :class="`bot-message bot-message--${type} d-flex mt-2`">
    <div
      v-if="type !== 'user'"
      class="icon d-flex"
      :class="{ 'align-items-start': type == 'welcome', 'align-items-end': type !== 'welcome' }"
    >
      <div class="d-flex align-items-center icon" v-html="aiMagic" />
    </div>
    <div class="ml-2">
      <div v-if="botsName" class="bot-name mb-1">{{ botsName }}</div>

      <div v-if="type == 'loader'" class="message-text">
        <span class="mr-1">
          <EllipsisLoader />
        </span>
        <i>Thinking...</i>
      </div>
      <!-- See #renderMarkdown comment below about using v-html here -->
      <div v-else class="message-text" v-html="renderMarkdown(message)"></div>
    </div>
  </div>
</template>

<script lang="ts">
import EllipsisLoader from '@app/components/EllipsisLoader.vue'
import MarkdownIt from 'markdown-it'
import aiMagicIcon from '@images/icons/ai-magic.svg?raw'

export default {
  components: {
    EllipsisLoader,
  },
  props: {
    botsName: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'bot',
      required: false,
    },
  },
  data() {
    return {
      markdownToHtmlService: new MarkdownIt(),
    }
  },
  computed: {
    aiMagic() {
      return aiMagicIcon
    },
  },
  methods: {
    // Airops returns data in Markdown format
    // Here we use markdown-it library to convert it to HTML and properly render it.
    // output should be safe by default, and safe to use with v-html
    // see: https://github.com/markdown-it/markdown-it/issues/427#issuecomment-356767365
    renderMarkdown(msg) {
      return this.markdownToHtmlService.render(msg)
    },
  },
}
</script>

<style lang="sass" scoped>

.bot-name
  font-size: 8px

.bot-message
  font-size: 12px

  .message-text
    padding: 10px
    border-radius: 8px
    width: fit-content

  &--error
    .message-text
      background-color: $techDebtRed1

  &--bot
    .message-text
      background-color: $techDebtPurple1

  &--loader
    .message-text
      display: flex
      flex-direction: row
      padding: 10px
      background-color: $techDebtPurple1
      // hack required due to hardcoded style attributes in SVG
      ::v-deep .loader svg
        height: 38px!important

  &--user
    float: right
    text-align: right
    .message-text
      background-color: $techDebtPurple4
      color: white
      margin-left: auto

  &--welcome
    float: right
    text-align: left
    .message-text
      background-color: transparent
      padding: 0
      color: black
      margin-left: auto
      // needed for overwriting the Markdownit generated `p` attr
      ::v-deep p
        font-weight: 500!important

.bot-message:not(.bot-message--user)
  .message-text
    margin-right: auto

.icon
  color: $uePurple
  svg
    height: 18px
    width: 18px
</style>
