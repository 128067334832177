import posthog from 'posthog-js'

// set up posthog with our project's API key
export const setPosthog = (user) => {
  posthog.init('phc_NBKF2BpMtmt3cKTX6JxLbNXCdhbQkRgOaWwREkSC3K9', {
    api_host: 'https://us.i.posthog.com',
  })

  if (user) {
    posthog.identify(user.id, {
      email: user.email,
      name: user.name,
    })
  }
}
