<template>
  <div class="loader">
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  data() {
    return {}
  },
}
</script>
<style lang="sass" scoped>
.spinner-grow
  width: 1rem
  height: 1rem
  color: $uePurple
</style>
